import React from 'react';
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

const CompetitionSearchMenu = () => {
  const { t } = useTranslation();
  const active = 'mr-6 text-black transition ease-in-out duration-300 border-b-4 border-b-brand border-solid pb-2';
  const notActive = 'mr-6 hover:text-black hover:border-b-4 hover:border-b-brand hover:border-solid hover:pb-2 transition ease-in-out duration-300';
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : null;

  return (
      <div className="pt-2 pb-6 md:pb-8 text-lg md:text-xl overflow-y-auto md:overflow-y-hidden">
        <ul className="flex whitespace-nowrap">
          <li>
            <NavLink to="/competitions" end className={({ isActive }) => isActive ? active : notActive}>
              {t('rewards')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/competitions/points" end className={({ isActive }) => isActive ? active : notActive}>
              {t('spot')}
            </NavLink>
          </li>
          {role === 'Admin' &&
            <li>
              <NavLink to="/competitions/upload" end className={({ isActive }) => isActive ? active : notActive}>
                {t('fileUpload')}
              </NavLink>
            </li>
          }
        </ul>
      </div>
  )
}

export default CompetitionSearchMenu;