import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Context } from '../../commons/context';
import _ from 'lodash';
import Loader from '../_shared/Loader';
import Menu from '../Menu';
import PageTitle from './../_shared/PageTitle';
import ProfileAdvisor from './ProfileAdvisor';
import ProfileAgreements from "./ProfileAgreements";
import ProfileCard from './ProfileCard';
import ProfileData from './ProfileData';
import ProfileFirm from './ProfileFirm';
import ProfilePassword from "./ProfilePassword";
import Button from "../_shared/Button";
import JwtInterceptor from "../_shared/JwtInterceptors";

const API_URL = process.env.REACT_APP_API_URL;

const CardPopUp = ({ cardNumber, acceptFnc, rejectFnc, loader, setLoader, popUpErrorText }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const part1 = cardNumber ? cardNumber.substring(0, cardNumber.indexOf('X')) : '';
  const part2 = cardNumber ? cardNumber.substring(cardNumber.indexOf('X') + 1, cardNumber.lastIndexOf('X')) : '';
  const part3 = cardNumber ? cardNumber.substring(cardNumber.lastIndexOf('X') + 1) : '';

  const onSubmit = async (data) => {
    const number = part1 + data.FirstX + part2 + data.SecondX + part3;
    setLoader(true);
    acceptFnc(number);
  }

  return (
      <>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 max-h-80vh overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 lg:w-1/2 p-6 md:py-8 bg-white text-lg md:text-xl terms">
          <p className="font-nouvelrbold">{t('termsPopUpTextBold')}</p>
          <p>{t(`termsPopUpCardText`)}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5 font-semibold">
              {part1}
              <input type="text" {...register("FirstX", { required: true, maxLength: 1 })} maxLength="1" className="inline-block w-4 outline-none text-center bg-white border-b border-solid border-formBorder" />
              {part2}
              <input type="text" {...register("SecondX", { required: true, maxLength: 1 })} maxLength="1" className="inline-block w-4 outline-none text-center bg-white border-b border-solid border-formBorder" />
              {part3}
            </div>
            {(errors.FirstX || errors.SecondX) && <span className="text-error text-base">{t('errFieldRequired')}</span>}
            {popUpErrorText && <span className="text-error text-base">{popUpErrorText}</span>}
            <div className="md:flex mt-6 text-center">
              <button type="submit" className={`relative block w-full md:w-fit max-h-46px leading-3 p-4 border font-nouvelrbold text-base transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand ${loader ? 'pr-12' : ''}`}>
                {t('confirm')}
                {loader && <Loader position="top-3 right-5" />}
              </button>
              <Button anchor={t('close')} styles="mt-6 md:mt-0 md:ml-6 text-base leading-4" onClickFnc={rejectFnc} />
            </div>
          </form>
        </div>
      </>
  )
}

const Profile = () => {
  const { t } = useTranslation();
  const [data, setData] = useContext(Context);
  const [loader, setLoader] = useState(false);
  const [popupCard, setPopupCard] = useState(false);
  const [popUpErrorText, setPopUpErrorText] = useState('');

  useEffect (() => {
    if (data.user.cardNumber && !data.user.cardNumberConfirmedOn) {
      setPopupCard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const popUpAcceptHandler = (number) => {
    JwtInterceptor
        .post(`${API_URL}Users/confirmCardNumber`, {cardNumber: number})
        .then(() => {
          setData({
            type: 'SET_USER',
            payload: {
              ...data.user,
              cardNumberConfirmedOn: true,
            },
          });
          setPopupCard(false);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpErrorText(errorTxt);
          } else {
            setPopUpErrorText(t('errDefault'));
          }
        })
        .finally(() => {
          setLoader(false);
        });
  }

  const popUpRejectHandler = () => {
    setPopupCard(false);
  }

  return (
      <>
        <Menu />
        <PageTitle text={t('myProfile')} />
        <div className="md:flex">
          <img src={data.user.image ? data.user.image : '/images/contact.png'} alt="contact" className="h-fit md:w-1/2 md:max-w-650px" />
          <div className="mt-10 md:mt-0 md:ml-5 w-full">
            <ProfileData />
            <ProfileAgreements />
            {!_.isEmpty(data.user.mop) &&
              <ProfileAdvisor data={data.user.mop} />
            }
            {data.user.service &&
                <ProfileFirm data={data.user.service} />
            }
            <ProfileCard data={data.user} />
            <ProfilePassword />
          </div>
        </div>
        {popupCard && <CardPopUp cardNumber={data.user.cardNumber} acceptFnc={popUpAcceptHandler} rejectFnc={popUpRejectHandler} loader={loader} setLoader={setLoader} popUpErrorText={popUpErrorText} />}
      </>
  )
}

export default Profile;