import React from 'react';
import { useTranslation } from "react-i18next";

const UserRow = ({ user }) => {

  return (
      <tr className="even:bg-tableEven">
        <td className="px-2 py-4">{user.name}</td>
        <td className="px-2 py-4">{user.taxNumber}</td>
        <td className="px-2 py-4">{user.chief}</td>
        <td className="px-2 py-4 text-center">{user.noOfAdvisors}</td>
        <td className="px-2 py-4">{user.street} / {user.postcode} {user.city}</td>
      </tr>
  );
}

const WorkshopsTable = ({ users }) => {
  const { t } = useTranslation();
  const usersRow = users.map(user => <UserRow user={user} key={user.id} />);

  return (
      <div className="overflow-y-auto md:overflow-y-hidden">
        <table className="w-full">
          <thead className="w-full mt-4 text-base">
          <tr className="font-semibold text-left bg-black text-white">
            <th className="px-2 py-5 align-middle">{t('workshop')}</th>
            <th className="px-2 py-5 align-middle">{t('nip')}</th>
            <th className="px-2 py-5 align-middle">{t('serviceBoss')}</th>
            <th className="px-2 py-5 align-middle">{t('employeesNumber')}</th>
            <th className="px-2 py-5 align-middle">{t('serviceAddress')}</th>
          </tr>
          </thead>
          <tbody className="text-sm text-tableText">
          {usersRow}
          </tbody>
        </table>
      </div>
  );
}

export default WorkshopsTable;