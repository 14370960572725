import React from 'react';
import { useTranslation } from "react-i18next";
import Menu from '../Menu';
import InformationsDetails from "./InformationsDetails";
import InformationsMenu from "./InformationsMenu";

const Informations = () => {
  const { t } = useTranslation();
  const questionsArray = [t('questionFaq1'), t('questionFaq2'), t('questionFaq3'), t('questionFaq4'), t('questionFaq5'), t('questionFaq6')];
  const answersArray = [t('questionFaqAnswer1'), t('questionFaqAnswer2'), t('questionFaqAnswer3'), t('questionFaqAnswer4'), t('questionFaqAnswer5'), t('questionFaqAnswer6')];

  return (
      <>
        <Menu />
        <InformationsMenu />
        <div className="pt-4 md:py-4 md:py-6">
          <InformationsDetails title={t('faq')} questionsArray={questionsArray} answersArray={answersArray} />
        </div>
      </>
  )
}

export default Informations;