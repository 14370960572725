import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import Icon from "../_shared/Icon";

const ArrowHeader = ({ color, link, text }) => {
  return (
      <h3 className={`mb-6 md:mb-16 text-xl md:text-30px font-semibold ${color === 'white' ? 'text-white' : ''}`}>
        <Link to={link} className="flex items-center">
          {color === 'white' ?
            <Icon id="arrLeftWhite" viewBox="0 0 32 33" width="32" height="33" />
          :
            <Icon id="arrRight" viewBox="0 0 22 21" width="22" height="21" />
          }
          <span className="ml-3">{text}</span>
        </Link>
      </h3>
  )
}

ArrowHeader.defaultProps = {
  color: '',
  link: '/',
  text: ''
}

ArrowHeader.propTypes = {
  color: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default ArrowHeader;