import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../commons/context';
import { useNavigate } from 'react-router-dom';
import AuthContext from './_shared/AuthContext';
import Button from './_shared/Button';
import Icon from './_shared/Icon';
import JwtInterceptor from './_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const TermsPopUp = ({ acceptFnc, rejectFnc, setContactAccept }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [termAccept, setTermAccept] = useState(false);


  const termHandler = (e) => {
    setError(false);
    setTermAccept(e.currentTarget.checked);
  }

  const contactHandler = (e) => {
    setError(false);
    setContactAccept(e.currentTarget.checked);
  }

  const checkHandler = () => {
    if (termAccept) {
      setError(false);
      acceptFnc();
    } else {
      setError(true);
    }
  }

  return (
      <>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 max-h-80vh overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 lg:w-1/2 p-6 md:py-8 bg-white text-lg md:text-xl terms">
          <p className="font-nouvelrbold">{t('termsPopUpTextBold')}</p>
          <p dangerouslySetInnerHTML={{__html: t(`termsPopUpText`) }} />
          <div className="flex flex-col mt-3 text-base">
            <fieldset className="flex items-center">
              <input id="sendToServiceChiefs" type="checkbox" className="h-5 w-5 mr-2 accent-brand" onChange={(e) => termHandler(e)} />
              <label htmlFor="sendToServiceChiefs">* {t('termsPopUpChxTerm')}</label>
            </fieldset>
            <fieldset className="flex items-center">
              <input id="sendToServiceAdvisors" type="checkbox" className="h-5 w-5 mr-2 accent-brand" onChange={(e) => contactHandler(e)} />
              <label htmlFor="sendToServiceAdvisors">{t('termsPopUpChxContact')}</label>
            </fieldset>
            {error && <span className="text-error">{t('termsPopUpError')}</span>}
          </div>
          <div className="md:flex mt-6 text-center">
            <Button anchor={t('termsPopUpAcceptBtn')} styles="text-base leading-4" onClickFnc={checkHandler} />
            <Button anchor={t('termsPopUpRejectBtn')} styles="mt-6 md:mt-0 md:ml-6 text-base leading-4" onClickFnc={rejectFnc} />
          </div>
        </div>
      </>
  )
}

const Header = ({ setBlurePage }) => {
  const { t } = useTranslation();
  const { acceptTerms, logout, authToken } = useContext(AuthContext);
  const [contactAccept, setContactAccept] = useState(false);
  const [data, setData] = useContext(Context);
  const [isShowing, setIsShowing] = useState(false);
  const [termsPopUp, setTermsPopUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';

  useEffect(() => {
    // if (role !== 'Admin' && role !== 'MOP') setBlurePage(false);

    if (!role) {
      navigate("/login");
    } else {
      if (!termsPopUp) {
        JwtInterceptor
            .get(`${API_URL}Users`)
            .then((response) => {
              setBlurePage(false);
              setData({
                type: 'SET_USER',
                payload: {
                  ...response.data,
                },
              });
            })
            .catch(function (error) {
              if (error.response.status !== 406) {
                logout();
              } else {
                setTermsPopUp(true);
                setBlurePage(true);
              }
            });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsPopUp]);

  useEffect(() => {
    setIsShowing(false);
  }, [location]);

  const profileMenuHandler = (e) => {
    e.preventDefault();
    setIsShowing((isShowing) => !isShowing)
  }

  const popUpAcceptHandler = () => {
    const obj = {
      "termsAccepted": true,
      "marketingContactAccepted": contactAccept,
      "token": authToken.token,
      "refreshToken": authToken.refreshToken
    }

    JwtInterceptor
        .post(`${API_URL}Users/acceptTerms`, obj)
        .then((response) => {
          acceptTerms(response.data);
          setTermsPopUp(false);
          setBlurePage(false);
        })
        .catch(function (error) {
          console.log('error', error);
        });
  }

  const popUpRejectHandler = () => {
    logout();
  }

  return (
      <header className="bg-motrioBg font-poppins">
        <div className="mx-auto flex max-w-screen-lg items-center justify-between p-5 md:py-10 md:px-60px" aria-label="Global">

          <div className="flex items-center">
            <a href="/#">
              <img src="/images/logomotrio.png" alt="Motrio logo" />
            </a>
          </div>

          <div className="flex items-cneter relative">
            {/*<a href="/#" className="flex items-center" onClick={(e) => profileMenuHandler(e)}>*/}
            {/*  <Icon id="bell" viewBox="0 0 24 25" width="24" height="25" />*/}
            {/*</a>*/}
            <a href="/" className="flex items-center ml-7" onClick={(e) => profileMenuHandler(e)}>
              <Icon id="userIcon" viewBox="0 0 24 25" width="24" height="25" />
            </a>
            <Transition
                as={Fragment}
                show={isShowing}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
              <div className="absolute right-0 z-10 flex flex-col mt-10 p-6 bg-white shadow-popover rounded">
                <Link to="/profile" className="mb-4 text-base font-light hover:underline">Mój profil</Link>
                <Button anchor={t('logout')} icon="logout" onClickFnc={logout} />
              </div>
            </Transition>
          </div>

        </div>

        {termsPopUp && <TermsPopUp acceptFnc={popUpAcceptHandler} rejectFnc={popUpRejectHandler} setContactAccept={setContactAccept} />}
      </header>
  )
}

export default Header;