import React, {useContext, useEffect, useState} from 'react';
import { images } from '../../commons/functions';
import Menu from '../Menu';
// import CompetitionsMenu from "./CompetitionsMenu";
import CompetitionsItem from "./CompetitionsItem";
// import CompetitionsItemMaterial from "./CompetitionsItemMaterial";
import CompetitionsItemInavtive from "./CompetitionsItemInavtive";
import LoaderBig from '../_shared/LoaderBig';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const cImages = images;

const Competitions = ({ blurePage }) => {
  const { logout, user } = useContext(AuthContext);
  const [competitions, setCompetitions] = useState([]);
  const [loader, setLoader] = useState(false);
  let competitionsItems;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!blurePage && user) {
      setLoader(true);
      JwtInterceptor
          .get(`${API_URL}Competitions/user`)
          .then((response) => {
            setCompetitions(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              logout();
            }
          })
          .finally(() => {
            setLoader(false);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blurePage]);

  if (competitions) {
    competitionsItems = competitions.map(competition => {
      if (competition.currentEdition) {
        return <CompetitionsItem data={competition} key={competition.competitionId} images={cImages} />
      } else {
        return <CompetitionsItemInavtive data={competition} key={competition.competitionId} images={cImages} />
      }
    });
  }

  return(
      <>
        <Menu />
        <div className="mx-auto max-w-screen-lg">
          {(blurePage && blurePage !== 'none' && !loader)  && <img src="/images/first_login.png" alt="competitions" />}
          {loader ?
            <LoaderBig />
          :
            competitions && competitionsItems
          }
        </div>
      </>
  )
}

export default Competitions;