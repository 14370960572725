import React, { useReducer, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from "./components/_shared/AuthContext";
import { DataReducer, DefaultData } from './commons/data-reducer';
import { Context } from './commons/context';
import Layout from './components/Layout';
import AddUser from './components/AddUser/AddUser';
import Competition from "./components/Competition/Competition";
import CompetitionSearch from "./components/CompetitionSearch/CompetitionSearch";
import CompetitionSearchUpload from "./components/CompetitionSearch/CompetitionSearchUpload";
import Competitions from "./components/Competitions/Competitions";
import Informations from "./components/Informations/Informations";
import InformationsFaq from "./components/Informations/InformationsFaq";
import LoginPage from './components/LoginPage/LoginPage';
import Page404 from './components/Page404';
import Participants from "./components/Participants/Participants";
import Profile from './components/Profile/Profile';
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Statistics from "./components/Statistics/Statistics";
import Workshop from "./components/Workshop/Workshop";
import WorkshopCard from './components/Workshop/WorkshopCard';
import WorkshopPoints from './components/Workshop/WorkshopPoints';
import './App.css';

function App() {
  const [data, setData] = useReducer(DataReducer, DefaultData);
  const [blurePage, setBlurePage] = useState('none');
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : null;

  return (
    <AuthContextProvider>
      <Context.Provider value={[data, setData]}>
        <Routes>
          <Route path="/" element={<Layout setBlurePage={setBlurePage} />}>
            <Route index element={role && (role === 'Admin' || role === 'MOP') ? <Participants blurePage={blurePage} /> : <Competitions blurePage={blurePage} />} />
            <Route path="add-user/:id?" element={<AddUser />} />
            <Route path="informations" element={<Informations />} />
            <Route path="informations/faq" element={<InformationsFaq />} />
            <Route path="competitions/:points?" element={<CompetitionSearch />} />
            <Route path="competitions/upload" element={<CompetitionSearchUpload />} />
            <Route path="login-statistics/:period?" element={<Statistics />} />
            <Route path="profile" element={<Profile />} />
            <Route path="workshop" element={<Workshop />} />
            <Route path="workshop/card" element={<WorkshopCard />} />
            <Route path="workshop/points" element={<WorkshopPoints />} />
            <Route path="card" element={<WorkshopCard />} />
          </Route>
          <Route path="competition/:id" element={<Layout userSection={false} main={false} />}>
            <Route index element={<Competition />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path='*' element={<Page404 />}/>
        </Routes>
      </Context.Provider>
    </AuthContextProvider>
  );
}

export default App;
