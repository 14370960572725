import React from 'react';
import { useTranslation } from "react-i18next";
import EditTitle from '../_shared/EditTitle';
import Icon from "../_shared/Icon";
import DownloadLink from "../_shared/DownloadLink";

const ProfileCard = ({ data }) => {
  const { t } = useTranslation();


  return (
      <div>
        <EditTitle text={t('myCard')} />
        <div className="w-full mb-10">
          <p className="flex text-base w-full">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('cardNumber')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.cardNumber ? data.cardNumber : '-'}</span>
          </p>
          {data.cardBalance &&
              <p className="flex text-base mt-4">
                <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('cardBalance')}</span>
                <span className="inline-block w-6/12 md:w-8/12">{data.cardBalance} zł</span>
              </p>
          }
          <DownloadLink text={t('cardRulesDownloadAnchor')} link="/pdf/eprl_regulamin_uzytkowania_przedplaconej_karty_edenred_11_03_2024_PL.pdf" />
          <DownloadLink text={t('howCartActivate')} link="/pdf/card_neut_std_2_druk.pdf" />
          <p className="mt-5">
            <a href="https://nowy.myedenred.pl/" target="_blank" rel="noreferrer" className="flex items-center font-semibold transition ease-in-out duration-300 stroke-black hover:text-brand">
              <Icon id="card" viewBox="0 0 20 16" width="20" height="16" clsWrap="mr-2.5" />
              {t('checkCardStatus')}
            </a>
          </p>
        </div>
      </div>
  );
}

export default ProfileCard;