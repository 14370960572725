import React, {useContext, useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../_shared/Button";
import EditTitle from '../_shared/EditTitle';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";
import {Context} from "../../commons/context";

const API_URL = process.env.REACT_APP_API_URL;

const PopUpAgree = ({ setPopUpAgree, setSaveData, contactAcceptTemp, setContactAccept }) => {
  const { t } = useTranslation();

  const closePopUpHandler = () => {
    setSaveData(false);
    setPopUpAgree(false);
    setContactAccept(contactAcceptTemp);
  }

  const yesHandler = () => {
    setSaveData(true);
    setPopUpAgree(false);
  }

  return (
      <div>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-fit p-6 bg-white">
          <p className="text-base">{t('removeCommunicationPopUpText')}</p>
          <div className="flex justify-center mt-5">
            <Button anchor={t('yes')} icon="check" onClickFnc={yesHandler} />
            <Button anchor={t('no')} icon="x" onClickFnc={closePopUpHandler} styles="ml-5" />
          </div>
        </div>
      </div>
  );
}

const ProfileAgreements = () => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const { acceptTerms, authToken } = useContext(AuthContext);
  const [data, setData] = useContext(Context);
  const [contactAccept, setContactAccept] = useState(false);
  const [contactAcceptTemp, setContactAcceptTemp] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [popUpAgree, setPopUpAgree] = useState(false);
  const [saveData, setSaveData] = useState(false);

  useEffect(() => {
    if (data.user.marketingContactAcceptedOn !== null && data.user.marketingContactAcceptedOn !== false) {
      setContactAccept( true);
      setContactAcceptTemp(true);
    } else {
      setContactAccept( false);
      setContactAcceptTemp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.user.marketingContactAcceptedOn]);

  useEffect(() => {
    const saveFnc = () => {
      setLoader(true);
      const obj = {
        "termsAccepted": true,
        "marketingContactAccepted": contactAccept,
        "token": authToken.token,
        "refreshToken": authToken.refreshToken
      }

      JwtInterceptor
          .post(`${API_URL}Users/acceptTerms`, obj)
          .then((response) => {
            acceptTerms(response.data, false);
            setData({
              type: 'SET_USER',
              payload: {
                ...data.user,
                marketingContactAcceptedOn: contactAccept,
              },
            });
            setPopUpType('success');
            setPopUpText('Zgoda została zmieniona');
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          })
          .finally(() => {
            setLoader(false);
            setSaveData(false);
            setIsEdit(false);
            setPopUp(true);
          });
    }

    if (saveData) saveFnc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  const onSubmit = async (data) => {
    if (!contactAccept) {
      setPopUpAgree(true);
    } else {
      setSaveData(true);
    }
  };

  const contactHandler = (e) => {
    setContactAccept(e.currentTarget.checked);
  }

  const editHandler = (e) => {
    e.preventDefault();
    if (isEdit) {
      setContactAccept(contactAcceptTemp);
    }
    setIsEdit(prevState => !prevState);
  }

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <div className="">
        <EditTitle editLinkText={t('change')} editFnc={(e) => editHandler(e)} isEdit={isEdit} isLoading={loader} sendFnc={handleSubmit(onSubmit)} text={t('myAgreements')} />
        <form className="w-full mb-10" >
          <fieldset className="flex items-start">
            <input id="contactAgree" type="checkbox" className="h-5 w-5 mr-2 accent-brand" disabled={!isEdit} checked={contactAccept} onChange={(e) => contactHandler(e)} />
            <label htmlFor="contactAgree" className="leading-5">{t('termsPopUpChxContact')}</label>
          </fieldset>
        </form>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
        {popUpAgree && <PopUpAgree setPopUpAgree={setPopUpAgree} setSaveData={setSaveData} contactAcceptTemp={contactAcceptTemp} setContactAccept={setContactAccept} />}
      </div>
  );
}

export default ProfileAgreements;