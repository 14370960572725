import React, { useContext, useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import _ from 'lodash';
import LoaderBig from '../_shared/LoaderBig';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const StatisticsColumnsTableRow = ({ rowData }) => {
  const periods = rowData.periods.map(period => <td className="px-2 py-4 text-center" key={period.period}>{period.logsQuantity}</td>);
  return(
      <tr className="even:bg-tableEven">
        <td className="px-2 py-4">{rowData.userFullName}</td>
        <td className="px-2 py-4">{rowData.userName}</td>
        {periods}
      </tr>
  )
};

const StatisticsDetailedComparasion = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const { period } = useParams();
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [tableData, setTableData] = useState([]);
  let rows, ths = [];

  useEffect(() => {
    setLoader(true);

    JwtInterceptor
        .get(`${API_URL}Statistics/loginLogsUsers?period=${period ? 'weekly' : 'monthly'}`)
        .then((response) => {
          setTableData(response.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUpType('error');
          setPopUp(true);
        })
        .finally(() => {
          setLoader(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  ths = !_.isEmpty(tableData) ? tableData[0].periods.map(period => <th className="px-2 py-5 align-middle text-center" key={period.period}>{period.periodName}</th>) : [];
  rows = !_.isEmpty(tableData) ? tableData.map(row => <StatisticsColumnsTableRow rowData={row} key={row.userName} />) : [];

  return (
      <div className="w-full overflow-y-auto md:overflow-y-hidden">
        {loader && <LoaderBig />}
        <p className="mb-5  text-base font-semibold">{t('overallSummary')}</p>
        <table className="w-full">
          <thead className="text-base">
            <tr className="font-semibold text-left bg-black text-white">
              <th className="px-2 py-5 align-middle">{t('participant')}</th>
              <th className="px-2 py-5 align-middle">{t('login')}</th>
              {ths}
            </tr>
          </thead>
          <tbody className="text-sm text-tableText">
            {rows}
          </tbody>
        </table>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </div>
  );
}

export default StatisticsDetailedComparasion;