import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Icon from './Icon';

const ButtonLink = ({ anchor, href, icon, iconLeft, styles}) => {
  return (
      <Link to={href} className={`relative max-h-46px px-4 py-15px font-semibold border border-black border-solid transition ease-in-out duration-300 stroke-black hover:cursor-pointer hover:bg-brand hover:border-brand hover:text-white hover:stroke-white ${icon ? 'pr-14' : ''} ${iconLeft ? 'pl-14' : ''} ${styles}`}>
        {iconLeft &&
            <Icon id={iconLeft} viewBox="0 0 24 24" width="24" height="24" clsWrap="absolute top-1/2 left-4 transform -translate-y-1/2" />
        }
        {anchor}
        {icon &&
          <Icon id={icon} viewBox="0 0 24 24" width="24" height="24" clsWrap="absolute top-1/2 right-4 transform -translate-y-1/2" />
        }
      </Link>
  )
}

ButtonLink.defaultProps = {
  anchor: '',
  href: '/#',
  icon: false,
  iconLeft: false,
  styles: ''
}

ButtonLink.propTypes = {
  anchor: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  iconLeft: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  styles: PropTypes.string,
}


export default ButtonLink;