import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Loader from '../_shared/Loader';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const LoginForm = ({ setLoginReminder }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data) => {
    if (!loader) {
      setLoader(true);
      setError(false);

      axios
        .post(`${API_URL}Users/resetPassword`, data)
        .then(() => {
          setSuccess(true);
        })
        .catch(function (error) {
          setError(true);
        }).finally(() => {
          setLoader(false);
        });
    }
  };

  const loginReminderHandler = (e) => {
    e.preventDefault();
    setLoginReminder(false);
  }

  return (
      <div className="relative md:w-1/2 md:mt-0 py-20 md:py-0 bg-loginBg bg-cover text-base">
        <form onSubmit={handleSubmit(onSubmit)} className="bg-loginFormBg md:w-6/12 md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 mx-5 md:mx-0 px-12 py-8 md:py-72px rounded-3xl backdrop-blur">
          <p className="mb-8 font-semibold text-30px text-white text-center">{t('loginPasswordReminder')}</p>
          <div className="relative mt-8 float-label-input">
            <input type="text" id="email" placeholder=" " {...register("email", { required: true, maxLength: 80, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z-]+)+$/i})} className="block w-full py-1.5 text-white outline-none border-b border-solid border-formBorderLogin bg-transparent" />
            <label htmlFor="email" className="absolute top-1.5 left-0 text-white pointer-events-none transition duration-200">{t('emailAdress')} *</label>
            {errors.email && errors.email.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.email && errors.email.type === "pattern" && <span className="text-error">{t('errFieldEmail')}</span>}
          </div>
          <p className="mt-2 text-right">
            <a href="/" className="text-beigeText text-right" onClick={loginReminderHandler}>{t('loginPasswordReminderParagraph')}</a>
          </p>
          {error && <p className="mt-8 p-4 text-error text-center border border-error border-solid">{t('errDefault')}</p>}
          {success && <p className="mt-8 p-4 text-green-700 text-center border border-green-700 border-solid">{t('successEmailReminder')}</p>}
          <button type="submit" className="relative w-full mt-8 p-4 border font-semibold text-white text-basee">
            {t('send')}
            {loader && <Loader styles="fill-white" />}
          </button>
        </form>
      </div>
  );
};

export default LoginForm;
