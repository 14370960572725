export const reverseDate = (date) => {
  return date.split('-').reverse().join('-');
}

export const images = {
  points: 'cPoints.png',
  materialPrize: 'cBody.png',
  materialPrizeCatalog: 'engine.png'
}

export const pdfs = {
  points: '/pdf/regulamin_konkurs_lap_punty_w_rpartstore_2024_t3.pdf',
  materialPrize: '/pdf/regulamin_konkursu_karoseria_edycja_II_2024_fv.pdf',
  materialPrizeCatalog: '/pdf/regulamin_konkursu_wygraj_nagrody_w_motrio_edycja_II_2024_fv.pdf'
}