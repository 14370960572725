import React, { useContext, useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import _ from 'lodash';
import LoaderBig from '../_shared/LoaderBig';
import StatisticsChart from './StatisticsChart';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const StatisticsColumnsTableRow = ({ rowData }) => {
  return(
      <tr className="even:bg-tableEven">
        <td className="px-2 py-4 text-center">{rowData.period}</td>
        <td className="px-2 py-4">{rowData.periodName}</td>
        <td className="px-2 py-4 text-center">{rowData.logsQuantity}</td>
        <td className="px-2 py-4 text-center">{rowData.uniqueUsersQuantity}</td>
      </tr>
  )
};

const StatisticsColumns = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const { period } = useParams();
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [statisticsData, setStatisticsData] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setLoader(true);

    JwtInterceptor
        .get(`${API_URL}Statistics/loginLogs?period=${period ? 'weekly' : 'monthly'}`)
        .then((response) => {
          const tempRows = response.data.periods.map(row => <StatisticsColumnsTableRow rowData={row} key={row.periodName} />);

          setStatisticsData(response.data);
          setRows(tempRows);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUpType('error');
          setPopUp(true);
        })
        .finally(() => {
          setLoader(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <div className="flex flex-col lg:flex-row mt-10">
        {loader && <LoaderBig />}
        {!_.isEmpty(statisticsData) && !loader &&
          <StatisticsChart statisticsData={statisticsData} />
        }
        <div className="lg:w-1/2 mb-5 lg:mb-0 lg:ml-5">
          <p className="mb-5  text-base font-semibold">{t('overallSummary')}</p>
          <div className="overflow-y-auto lg:overflow-y-hidden">
            <table className="w-full">
              <thead className="text-base">
              <tr className="font-semibold text-left bg-black text-white">
                <th className="px-2 py-5 align-middle">{period ? t('week') : t('month')}</th>
                <th className="px-2 py-5 align-middle">{t('date')}</th>
                <th className="px-2 py-5 align-middle">{t('numberOfLogs')}</th>
                <th className="px-2 py-5 align-middle">{t('numberOfLogins')}</th>
              </tr>
              </thead>
              <tbody className="text-sm text-tableText">
                {rows}
              </tbody>
            </table>
          </div>
        </div>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </div>
  );
}

export default StatisticsColumns;