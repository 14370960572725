import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLink from "../_shared/ButtonLink";

const CompetitionsItemInactive = ({ data, images }) => {
    const { t } = useTranslation();

    return (
        <div className="md:flex mt-5 mb-10 md:my30px">
            <img src={`/images/${images[data.competitionId]}`} alt={data.competitionName} className="w-full md:w-auto mb-3 md:mb-0 md:mr-10" />
            <div className="relative w-full">
              <div className="md:absolute w-full overflow-hidden md:right-0 md:top-1/2 md:transform md:-translate-y-1/2">
                <h3 className="mt-3 md:mt-8 md:mb-4 font-nouvelrbold md:text-40px mb-5 font-semibold text-3xl lg:text-40px">{data.competitionName}</h3>
                <p className="mb-10 md:mb-20 text-xl">{t('competitionInactiveText')}</p>
                <ButtonLink anchor={t('more')} href={`/competition/${data.competitionId}`} styles="float-right" />
              </div>
            </div>
        </div>
    )
}

export default CompetitionsItemInactive;