import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import _ from 'lodash';
import ArrowHeader from '../_shared/ArrowHeader';
import CompetitionProgress from "./CompetitionProgress";
import CompetitionText from "./CompetitionText";
import CompetitionPrev from "./CompetitionPrev";
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const Competition = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const { id } = useParams();
  const [competition, setCompetition] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    JwtInterceptor
        .get(`${API_URL}Competitions/user/${id}`)
        .then((response) => {
          setCompetition(response.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
      <>
        <section className="bg-motrioBg">
          <div className="mx-auto max-w-screen-lg py-4 md:py-12 px-6 md:px-60px">
            <ArrowHeader color="white" text={t('competitions')} styles="text-white" />
            <CompetitionProgress competitionId={competition.competitionId} progressData={competition.currentEdition} title={competition.competitionName} />
          </div>
        </section>
        <div className="mx-auto max-w-screen-lg pb-4 md:pb-12 px-6 md:px-60px">
          <div className="lg:flex">
            <CompetitionPrev competitionType={id} otherEditions={competition.otherEditions} />
            <CompetitionText competitionType={id} missingFirstPlaceFormatted={(!_.isEmpty(competition) && !_.isEmpty(competition.currentEdition)) ? competition.currentEdition.missingFirstPlaceFormatted : null} missingNextTresholdFormatted={(!_.isEmpty(competition) && !_.isEmpty(competition.currentEdition)) ? competition.currentEdition.missingNextTresholdFormatted : null} />
          </div>
        </div>
      </>
  )
}

export default Competition;