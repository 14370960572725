import React from 'react';
import { NavLink } from 'react-router-dom';

const Menu = () => {
  const active = 'mr-6 text-black font-semibold transition ease-in-out duration-300 border-b-3px border-b-brand border-solid pb-2';
  const notActive = 'mr-6 hover:text-black font-semibold hover:border-b-brand hover:border-solid hover:pb-2 transition ease-in-out duration-300 menulink';
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';

  return (
    <nav className="mx-auto flex max-w-screen-lg pb-4 md:pb-6 font-semibold text-xl md:text-3xl overflow-x-scroll xs:overflow-x-hidden">
      <ul className="flex whitespace-nowrap">
        {(role === 'Admin' || role === 'MOP') &&
          <li>
            <NavLink to="/" className={({ isActive }) => isActive ? active : notActive}>
              Baza uczestników
            </NavLink>
          </li>
        }
        <li>
          <NavLink to={(role === 'Admin' || role === 'MOP') ? '/competitions' : '/'} className={({ isActive }) => isActive ? active : notActive}>
            Konkursy
          </NavLink>
        </li>
        {role === 'Admin' &&
          <li>
            <NavLink to="/login-statistics" className={({ isActive }) => isActive ? active : notActive}>
              Statystyki logowań
            </NavLink>
          </li>
        }
        {role !== 'Admin' && role !== 'MOP' &&
          <>
            <li>
              <NavLink to="/informations" className={({ isActive }) => isActive ? active : notActive}>
                Informacje
              </NavLink>
            </li>
            {role === 'ServiceChief' ?
              <li>
                <NavLink to="/workshop" className={({ isActive }) => isActive ? active : notActive}>
                  Mój warsztat
                </NavLink>
              </li>
            :
              <li>
                <NavLink to="/card" className={({ isActive }) => isActive ? active : notActive}>
                  Moja karta
                </NavLink>
              </li>
            }
          </>
        }
      </ul>
    </nav>
  )
}

export default Menu;