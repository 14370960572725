import React from 'react';
import { useTranslation } from "react-i18next";
import Menu from '../Menu';
import InformationsBanner from './InformationsBanner';
import InformationsDetails from "./InformationsDetails";
import InformationsHalfBanner from './InformationsHalfBanner';
import InformationsMenu from "./InformationsMenu";

const Informations = () => {
  const { t } = useTranslation();
  const questionsArray = [t('question1'), t('question2'), t('question3'), t('question4')];
  const answersArray = [t('questionAnswer1'), t('questionAnswer2'), t('questionAnswer3'), t('questionAnswer4')];

  return (
      <>
        <Menu />
        <InformationsMenu />
        <div className="pt-4 md:py-4 md:py-6">
          <InformationsBanner />
          <InformationsDetails title={t('getTheDetails')} questionsArray={questionsArray} answersArray={answersArray} />
          <InformationsHalfBanner />
        </div>
      </>
  )
}

export default Informations;