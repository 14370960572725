import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CompetitionSearchMenu from "./CompetitionSearchMenu";
import Menu from "../Menu";
import Loader from "../_shared/Loader";
import PopUp from '../_shared/PopUp';
import JwtInterceptor from "../_shared/JwtInterceptors";

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionSearchUpload = () => {
  const { t } = useTranslation();
  const { register, reset, handleSubmit, formState: { errors } } = useForm();
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const formData = new FormData();

  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onSubmit = async (data) => {
    setLoader(true);

    formData.append('file', data.File[0]);

    JwtInterceptor
        .post(`${API_URL}Import/results`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(() => {
          setPopUpText(t('ImportSuccess'));
          setPopUpType('success');
          reset();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUpType('error');
        }).finally(() => {
      setLoader(false);
      setPopUp(true);
    });
  };

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <div>
        <Menu />
        <CompetitionSearchMenu />
        <form className="mt-10 text-base" onSubmit={handleSubmit(onSubmit)}>
          <label className="text-sm md:w-1/3 mb-4">
            <span className="inline-block mr-4 font-nouvelr">{t('fileSelect')}</span>
            <input type="file" {...register("File", { required: true})} defaultValue="" className={`inline-block outline-none bg-transparent`} />
            {errors.File && errors.File.type === "required" && <span className="block text-error">{t('errFieldRequired')}</span>}
          </label>
          <div>
            <button type="submit" className={`relative w-fit mt-8 p-4 border font-nouvelrbold text-black transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand hover:text-white ${loader ? 'pr-12' : ''}`}>
              {t('update')}
              {loader && <Loader />}
            </button>
          </div>
        </form>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </div>
  )
}

export default CompetitionSearchUpload;