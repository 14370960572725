import React from 'react';
import { images } from '../../commons/functions';
import { reverseDate } from "../../commons/functions";
import { useTranslation } from 'react-i18next';
import ProgressBarItem from "../_shared/ProgressBarItem";

const CompetitionProgress = ({ competitionId, progressData, title }) => {
  const { t } = useTranslation();

  return (
      <div>
        <img className="md:absolute" src={`/images/${images[competitionId]}`} alt={title} />
        <div className="md:flex">
          <div className="md:w-2/5"></div>
          <div className="md:realtive md:z-10 md:w-3/5 mx-m24px md:mx-0 mt-m24px md:mt-10 px-5 py-5 pt-12 pb-8 md:p-10 bg-lightGrayBg md:rounded">
            {progressData ?
              <ProgressBarItem
                  competitionId={progressData.competitionId}
                  dates={`${reverseDate(progressData.dateFrom)} - ${reverseDate(progressData.dateTo)}`}
                  lastImportDate={progressData.lastImportDate}
                  material={progressData.competitionId !== 'points'}
                  missingFirstPlaceFormatted={progressData.missingFirstPlaceFormatted}
                  missingNextTresholdFormatted={progressData.missingNextTresholdFormatted}
                  position={progressData.position}
                  prize={progressData.prize}
                  score={progressData.score}
                  resultsDate={progressData.resultsDate}
                  title1={title}
                  title2={progressData.editionName}
                  turnover={progressData.turnoverFormatted}
                  link={false}
              />
            :
              <div className="w-full">
                <h3 className="mb-5 font-semibold text-3xl lg:text-40px mt-3 md:mt-8 md:mb-4 font-nouvelrbold md:text-56px">{title}</h3>
                <p className="md:mb-52 text-xl">{t('competitionInactiveText')}</p>
              </div>
            }
          </div>
        </div>
      </div>
  )
}

export default CompetitionProgress;