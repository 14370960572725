import React from 'react';
import Menu from '../Menu';
import WorkshopMenu from "./WorkshopMenu";
import WorkshopWorkers from "./WorkshopWorkers";

const Workshop = () => {
  return (
      <>
        <Menu />
        <WorkshopMenu />
        <WorkshopWorkers />
      </>
    );
}

export default Workshop;