import React from 'react';
import { useTranslation } from "react-i18next";
import EditTitle from '../_shared/EditTitle';

const ProfileFirm = ({ data }) => {
  const { t } = useTranslation();

  return (
      <div>
        <EditTitle text={t('myCompany')} />
        <div className="w-full mb-10">
          <p className="flex text-base w-full">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('companyName')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.name}</span>
          </p>
          <p className="flex text-base mt-4">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('birNumber')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.externalId}</span>
          </p>
          <p className="flex text-base mt-4">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('serviceBoss')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.chief}</span>
          </p>
          <p className="flex text-base mt-4">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('serviceAddress')}</span>
            <span className="inline-block w-6/12 md:w-8/12">Ul. {data.street} {data.postcode} {data.city}</span>
          </p>
        </div>
      </div>
  );
}

export default ProfileFirm;