import React from 'react';
import {useTranslation} from "react-i18next";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';

const ChartLineConfig = ({ labels, dataSet, dataSet1, dataSetTitle, dataSet1Title }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        title: {
          display: true,
          text: ``
        }
      },
      y: {
        title: {
          display: false,
          text: 'Wartości'
        }
      },
    },
  };

  const datasetTab = [];

  if (dataSet) {
    datasetTab.push({
      label: dataSetTitle,
      data: dataSet,
      borderColor: '#f00',
      backgroundColor: '#f00',
    });
  }

  if (dataSet1) {
    datasetTab.push({
      label: dataSet1Title,
      data: dataSet1,
      borderColor: '#1e1f21',
      backgroundColor: '#1e1f21',
    });
  }

  const config = {
    labels: labels,
    datasets: datasetTab
  };

  return <Line options={options} data={config} />;
};

const StatisticsChart = ({ statisticsData }) => {
  const { t } = useTranslation();
  const labels = statisticsData.periods.map(label => label.periodName);
  const dataSetLogsQuantity = statisticsData.periods.map(log => log.logsQuantity);
  const dataSetUniqueUserQuantity = statisticsData.periods.map(log => log.uniqueUsersQuantity);

  return (
      <div className="lg:w-1/2">
        <p className="mb-5 text-base font-semibold">{t('chart')}</p>
        <ChartLineConfig labels={labels} dataSet={dataSetLogsQuantity} dataSetTitle="Ilość logów" dataSet1={ dataSetUniqueUserQuantity} dataSet1Title="Ilość logujących" />
      </div>
  );
}

export default StatisticsChart;