import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from './_shared/AuthContext';
import Header from './Header';
import Footer from './Footer';
import UserSection from "./UserSection";

const Layout = ({ setBlurePage, userSection = true, main = true}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return(
      <>
        <Header setBlurePage={setBlurePage} />
        {userSection &&
          <UserSection />
        }
        {main ?
          <main className="mx-auto max-w-screen-lg py-4 md:py-12 px-6 md:px-60px font-poppins">
            <Outlet />
          </main>
        :
          <main className="font-poppins">
            <Outlet />
          </main>
        }
        <Footer />
      </>
  )
}

export default Layout