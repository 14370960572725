import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
// import CompetitionSearchFilters from "./CompetitionSearchFilters";
import CompetitionSearchMenu from "./CompetitionSearchMenu";
import CompetitionSearchTable from "./CompetitionSearchTable";
import LoaderBig from '../_shared/LoaderBig';
import Menu from '../Menu';
// import Pagination from "../_shared/Pagination";

import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionSearch = () => {
  const { logout } = useContext(AuthContext);
  const { points } = useParams();
  // const [bir, setBir] = useState('');
  const [competitionsData, setCompetitionsData] = useState({});
  // const [filterCompetition, setFilterCompetition] = useState('');
  // const [filterService, setFilterService] = useState('');
  // const [filterParams, setFilterParams] = useState('');
  const [loader, setLoader] = useState(false);
  // const [page, setPage] = useState(1);
  // const [tempBir, setTempBir] = useState('');
  const [reloadTable, setReloadTable] = useState(false);

  const getTable = () => {
    // const inactive = !!points;
    // const competitions = filterCompetition.length > 0 ? filterCompetition.map(competition => {return competition.value}) : '';
    // const competitionsQuery = competitions.length > 0 ? '&CompetitionIds=' + competitions.join('&CompetitionIds=') : '';
    // const services = filterService.length > 0 ? filterService.map(service => {return service.value}) : '';
    // const servicesQuery = services.length > 0 ? '&ServiceExternalIds=' + services.join('&ServiceExternalIds=') : '';
    // const birQuery = bir ? '&ServiceExternalIds=' + bir : '';
    // const queryParams = `?ShowInactive=${inactive}${competitionsQuery}${servicesQuery}${birQuery}`;
    let queryParams = '';

    if (points) {
      queryParams = '?CompetitionIds=points';
    } else {
      queryParams = '?CompetitionIds=materialPrize&CompetitionIds=materialPrizeCatalog';
    }

    window.scrollTo(0, 0);

    // setFilterParams(queryParams);
    setLoader(true);

    JwtInterceptor
        // .get(`${API_URL}Competitions${queryParams}&PageIndex=${page}`)
        .get(`${API_URL}Competitions${queryParams}`)
        .then((response) => {
          setCompetitionsData(response.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
        })
        .finally(() => {
          setLoader(false);
          setReloadTable(false);
        });
  }

  useEffect(() => {
    getTable();
  // }, [filterCompetition, filterService, bir, page, points, logout]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, logout]);

  useEffect(() => {
    if (reloadTable) getTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTable]);

  // const clearFiltersHandler = () => {
  //   setBir('');
  //   setFilterCompetition('');
  //   setFilterService('');
  //   setTempBir('');
  //   setPage(1);
  // }

  return (
      <div>
        <Menu />
        <CompetitionSearchMenu />
        {/*<CompetitionSearchFilters availableCompetitions={competitionsData.availableCompetitions} availableServices={competitionsData.availableServices} bir={bir} setBir={setBir} filterCompetition={filterCompetition} setFilterCompetition={setFilterCompetition} filterService={filterService} setFilterService={setFilterService} allResults={competitionsData.numberOfAllResults} setPage={setPage} tempBir={tempBir} setTempBir={setTempBir} />*/}
        {loader && <LoaderBig />}
        {competitionsData && !loader &&
          <CompetitionSearchTable competitions={competitionsData.competitions} setReloadTable={setReloadTable} />
        }
        {/*{competitionsData.competitions && competitionsData.competitions.length > 0 &&*/}
        {/*  <Pagination actualPage={page} allPage={Number(competitionsData.numberOfPages)} setPage={setPage} />*/}
        {/*}*/}
      </div>
  );
}

export default CompetitionSearch;