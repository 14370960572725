import React from 'react';
import Icon from './Icon';
import PropTypes from "prop-types";

const DownloadLink = ({ text, link }) => {
  return (
      <a href={link} target="_blank" rel="noreferrer" className={`block py-4 text-base font-semibold transition ease-in-out duration-300 stroke-black hover:text-brand`}>
        <Icon id="download" viewBox="0 0 24 24" width="24" height="24" clsWrap="float-left mr-2.5" />
        <span className="shrink-0 block w-full">{text}</span>
      </a>
  )
}

DownloadLink.defaultProps = {
  text: 'Pobierz',
  link: '/#'
}

DownloadLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
}

export default DownloadLink;