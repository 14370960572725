import React from 'react';
import { useTranslation } from "react-i18next";
import EditTitle from '../_shared/EditTitle';

const ProfileCard = ({ data }) => {
  const { t } = useTranslation();

  return (
      <div>
        <EditTitle text={t('ourAdvisor')} />
        <div className="w-full mb-10">
          <p className="flex text-base mt-4">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('serviceSupervisor')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.firstName} {data.lastName}</span>
          </p>
          <p className="flex text-base mt-4">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('phone')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.phoneNumber}</span>
          </p>
          <p className="flex text-base mt-4">
            <span className="inline-block font-semibold w-6/12 md:w-4/12">{t('email')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.email}</span>
          </p>
        </div>
      </div>
  );
}

export default ProfileCard;