import React from 'react';
import Menu from '../Menu';
import StatisticsColumns from "./StatisticsColumns";
import StatisticsDetailedComparasion from "./StatisticsDetailedComparasion"
import StatisticsMenu from "./StatisticsMenu";


const Statistics = () => {
  return (
      <>
        <Menu />
        <StatisticsMenu />
        <StatisticsColumns />
        <StatisticsDetailedComparasion />
      </>
  );
}

export default Statistics;