import React, { useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import AuthContext from '../_shared/AuthContext';
import Icon from '../_shared/Icon';
import Loader from '../_shared/Loader';

const LoginForm = ({ loginReminder, setLoginReminder }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { login, loginLoader, loginError } = useContext(AuthContext);
  const [passFieldType, setPassFieldType] = useState('password');

  const onSubmit = async (data) => {
    if (loginLoader) return;

    let payload = {
      userName: data.userName,
      password: data.password
    };

    await login(payload);
  };

  const changeType = () => {
    if (passFieldType === 'password') {
      setPassFieldType('text');
    } else {
      setPassFieldType('password');
    }
  };

  const loginReminderHandler = (e) => {
    e.preventDefault();
    setLoginReminder(true);
  }

  return (
      <div className="relative md:w-1/2 md:mt-0 py-20 md:py-0 bg-loginBg bg-cover text-base">
        <form onSubmit={handleSubmit(onSubmit)} className="bg-loginFormBg md:w-6/12 md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 mx-5 md:mx-0 px-12 py-8 md:py-72px rounded-3xl backdrop-blur">
          <p className="mb-8 font-semibold text-30px text-white text-center">{t('loginFormTitle')}</p>
          <div className="relative mt-4 float-label-input">
            <input type="text" id="userName" placeholder=" " {...register("userName", { required: true, maxLength: 50})} className="block w-full py-1.5 text-white outline-none border-b border-solid border-formBorderLogin bg-transparent" />
            <label htmlFor="userName" className="absolute top-1.5 left-0 text-white pointer-events-none transition duration-200">{t('login')}</label>
            {errors.userName && <span className="text-error">{t('errFieldRequired')}</span>}
          </div>
          <div className="relative mt-8 float-label-input">
            <input type={passFieldType} id="password" placeholder=" " {...register("password", { required: true, maxLength: 50})} className="block w-full py-1.5 text-white outline-none border-b border-solid border-formBorderLogin bg-transparent" />
            <label htmlFor="password" className="absolute top-1.5 left-0 font text-white pointer-events-none transition duration-200">{t('password')}</label>
            {errors.password && <span className="text-error">{t('errFieldRequired')}</span>}
            <Icon id="eye" viewBox="0 0 30 30" width="30" height="30" onClickFnc={changeType} clsWrap="absolute top-0 right-0 hover:cursor-pointer" />
          </div>
          <p className="mt-2 text-right">
            <a href="/" className="text-beigeText text-right" onClick={loginReminderHandler}>{t('loginPasswordReminder')}</a>
          </p>
          {loginError && <p className="mt-8 p-4 text-error text-center border border-error border-solid">{t('errInvalidLoginData')}</p>}
          <button type="submit" className="relative w-full mt-8 p-4 border font-semibold text-white text-basee">
            {t('loginBtn')}
            {loginLoader && <Loader styles="fill-white" />}
          </button>
        </form>
      </div>
  );
};

export default LoginForm;
