import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { reverseDate } from "../../commons/functions";
import Icon from '../_shared/Icon';

const CompetitionSearchTableDetailsRow = ({ service, last, competitionId }) => {
  return (
      <tr className={`even:bg-tableEven ${last ? 'border-b border-black border-solid border-b-2' : ''}`}>
        <td className="px-2 py-5 align-middle">{service.position}</td>
        <td className="px-2 py-5 align-middle">{service.serviceName}</td>
        <td className="px-2 py-5 align-middle text-right">{(competitionId !== 'materialPrize' && competitionId !== 'materialPrizeCatalog') ? service.score : ''}</td>
        <td className="px-2 py-5 align-middle text-right whitespace-nowrap">{service.turnoverFormatted}</td>
        <td className="px-2 py-5 align-middle text-right whitespace-nowrap">
          {competitionId !== 'materialPrize' ?
            service.prizeFormatted
          :
            <>
              {service.prizeFormatted === '1,00 zł' ?
                <Icon id='prize' viewBox="0 0 25 24" width="25" height="24" clsWrap="fill-white float-right" />
              :
                ''
              }
            </>
          }
        </td>
      </tr>
  );
}

const CompetitionSearchTableDetails = ({ services, competitionId }) => {
  const { t } = useTranslation();
  const trs = services.map((s, i) => <CompetitionSearchTableDetailsRow service={s} key={s.serviceName} last={services.length === i + 1} competitionId={competitionId} />);
  return (
      <>
        <tr className="bg-tableTableHeader text-base text-white font-semibold">
          <td className="px-2 py-2.5 align-middle font-nouvelrbold">{t('positionP')}</td>
          <td className="px-2 py-2.5 align-middle font-nouvelrbold">{t('workshop')}</td>
          <td className="px-2 py-2.5 align-middle font-nouvelrbold text-right">{(competitionId !== 'materialPrize' && competitionId !== 'materialPrizeCatalog') ? t('points') : ''}</td>
          <td className="px-2 py-2.5 align-middle font-nouvelrbold text-right">{t('turnover')}</td>
          <td className="px-2 py-2.5 align-middle font-nouvelrbold w-36 text-right">{t('prize')}</td>
        </tr>
        {trs}
      </>
  );
}

const CompetitionSearchTableRow = ({ row }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const usersOpenHandler = () => {
    setDetailsOpen(prevState => !prevState);
  }

  return (
      <>
        <tr className="even:bg-tableEven">
          <td colSpan={2} className="px-2 py-4 align-middle">{row.competitionName}</td>
          <td colSpan={2} className="px-2 py-4 align-middle whitespace-nowrap">{reverseDate(row.dateFrom)} - {reverseDate(row.dateTo)}</td>
          <td colSpan={1} className="flex justify-center px-2 py-4 align-middle">
            <Icon id="downArr" width="25" height="25" clsWrap={`hover:cursor-pointer ${detailsOpen ? 'rotate-180' : ''}`} onClickFnc={usersOpenHandler} />
          </td>
        </tr>
        {detailsOpen &&
          <CompetitionSearchTableDetails services={row.services} competitionId={row.competitionId} />
        }
      </>
  )
}

const CompetitionSearchTable = ({ competitions, setReloadTable }) => {
  const { t } = useTranslation();
  const rows = competitions ? competitions.map(row => <CompetitionSearchTableRow row={row} setReloadTable={setReloadTable} key={row.competitionId} />) : '';

  return (
      <div className="overflow-y-auto md:overflow-y-hidden">
        <table className="w-full mt-5">
          <thead className="bg-black font-semibold text-white text-base">
            <tr>
              <td colSpan={2} className="px-2 py-5 align-middle">{t('competition')}</td>
              <td colSpan={2} className="px-2 py-5 align-middle">{t('duration')}</td>
              <td colSpan={1} className="px-2 py-5 align-middle text-center">{t('details')}</td>
            </tr>
          </thead>
          {rows.length > 0 &&
            <tbody className="text-base">
              {rows}
            </tbody>
          }
        </table>
      </div>
  );
}

export default CompetitionSearchTable;