import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../commons/context';
import Icon from '../_shared/Icon';
import LoginForm from './LoginForm';
import LoginFormReminder from './LoginFormReminder';

const LoginPage = () => {
  const { t } = useTranslation();
  const [loginReminder, setLoginReminder] = useState(false);
  localStorage.removeItem('tempTokens');
  const [data, setData] = useContext(Context);

  useEffect(() => {
    setData({
      type: 'SET_USER',
      payload: {
        additionalInfo: '',
        cardBalance: '',
        cardBalanceUpdatedOn: '',
        cardNumber: '',
        cardNumberConfirmedOn: '',
        email: '',
        firstName: '',
        id: '',
        image: '',
        lastName: '',
        phoneNumber: '',
        roles: [],
        service: '',
        userName: '',
        mop: '',
        marketingContactAcceptedOn: null,
      },
    });
  }, []);

  return (
      <div className="md:flex md:h-svh font-poppins">
        <div className="relative md:w-1/2 bg-motrioBg">
          <Icon id="rServiceClub" viewBox="0 0 78 30" width="78" height="30" clsWrap="hidden md:block mt-8 ml-10" />
          <div className="md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 px-10 md:px-0 py-5 md:py-0">
            <h1 className="mb-4 font-semibold text-3xl md:text-40px text-white" dangerouslySetInnerHTML={{__html: t(`loginTitle`) }} />
            <p className="font-semibold md:font-normal text-lg md:text-xl text-white">{t('loginParagraph')}</p>
          </div>
        </div>
        {loginReminder ? <LoginFormReminder setLoginReminder={setLoginReminder} /> : <LoginForm setLoginReminder={setLoginReminder} />}
      </div>
  );
};

export default LoginPage;
