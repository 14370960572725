import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../commons/context';
import Icon from './_shared/Icon';

const UserSection = () => {
  const { t } = useTranslation();
  const [data] = useContext(Context);
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';
  const additionalInfo = data.user.additionalInfo ? data.user.additionalInfo.split('|') : [];

  return (
      <section className="border-b border-solid border-grayBorder">
        <div className="md:flex items-center justify-between mx-auto max-w-screen-lg md:px-60px p-5 md:py-10 font-poppins">
          <p className="mb-5 md:mb-0 font-semibold text-40px">
            {t('welcome')}<br />
            {`${data.user.firstName} ${data.user.lastName}`}
          </p>
          {role === "Admin" ?
            <div>
              <p className="flex items-center text-base">
                <Icon id="users" viewBox=" 0 0 20 19" width="20" height="19" clsWrap="mr-2" />
                {t('invited')}
                <span className="ml-2 font-bold text-lg">{additionalInfo[0]}</span>
              </p>
              <p className="flex items-center py-2 text-base">
                <Icon id="usersPlus" viewBox=" 0 0 20 20" width="20" height="20" clsWrap="mr-2" />
                {t('registered')}
                <span className="ml-2 font-bold text-lg">{additionalInfo[1]}</span>
              </p>
              <p className="flex items-center text-base">
                <Icon id="usersCheck" viewBox=" 0 0 20 20" width="20" height="20" clsWrap="mr-2" />
                {t('cardAcceptance')}
                <span className="ml-2 font-bold text-lg">{additionalInfo[2]}</span>
              </p>
            </div>
          :
            <div>
              <p className="flex items-center text-base">
                <Icon id="handPoints" viewBox=" 0 0 20 20" width="20" height="20" clsWrap="mr-2" />
                {t('pointsCollected1')} ({t('pointsCollected2')} {additionalInfo[0]})
                <span className="ml-2 font-bold text-lg">{additionalInfo[1]}</span>
              </p>
            </div>
          }
        </div>
      </section>
  );
}

export default UserSection;