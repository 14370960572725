import React from 'react';
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

const InformationsMenu = () => {
  const { t } = useTranslation();
  const active = 'mr-6 text-black transition ease-in-out duration-300 border-b-4 border-b-brand border-solid pb-2';
  const notActive = 'mr-6 hover:text-black hover:border-b-4 hover:border-b-brand hover:border-solid hover:pb-2 transition ease-in-out duration-300';

  return (
      <div className="pt-2 pb-6 md:pb-8 text-lg md:text-xl">
        <ul className="flex">
          <li>
            <NavLink to="/informations" end className={({ isActive }) => isActive ? active : notActive}>
              {t('aboutProgram')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/informations/faq" end className={({ isActive }) => isActive ? active : notActive}>
              {t('faq')}
            </NavLink>
          </li>
        </ul>
      </div>
  )
}

export default InformationsMenu;