import React from 'react';
import { reverseDate } from "../../commons/functions";
import ProgressBarItem from "../_shared/ProgressBarItem";

const CompetitionsItem = ({ data, images }) => {

  return (
      <div className="md:flex mt-5 mb-10 md:my30px">
        <div className="md:w-1/2 md:mr-10">
          <img src={`/images/${images[data.competitionId]}`} alt={data.competitionName} className="w-full md:w-auto mb-3 md:mb-0 md:mr-10" />
        </div>
        <ProgressBarItem
          competitionId={data.competitionId}
          dates={`${reverseDate(data.currentEdition.dateFrom)} - ${reverseDate(data.currentEdition.dateTo)}`}
          lastImportDate={data.currentEdition.lastImportDate}
          material={data.competitionId !== 'points'}
          missingFirstPlaceFormatted={data.currentEdition.missingFirstPlaceFormatted}
          missingNextTresholdFormatted={data.currentEdition.missingNextTresholdFormatted}
          position={data.currentEdition.position}
          prize={data.currentEdition.prize}
          score={data.currentEdition.score}
          resultsDate={data.currentEdition.resultsDate}
          title1={data.competitionName}
          title2={data.currentEdition.editionName}
          turnover={data.currentEdition.turnoverFormatted}
          link={true}
        />
      </div>
  )
}

export default CompetitionsItem;