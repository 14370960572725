import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Context } from '../../commons/context';
import Button from "../_shared/Button";
import DownloadLink from "../_shared/DownloadLink";
import Menu from '../Menu';
import WorkshopMenu from "./WorkshopMenu";
import JwtInterceptor from "../_shared/JwtInterceptors";
import Loader from "../_shared/Loader";
import Icon from "../_shared/Icon";

const API_URL = process.env.REACT_APP_API_URL;

const CardPopUp = ({ cardNumber, acceptFnc, rejectFnc, loader, setLoader, popUpErrorText }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const part1 = cardNumber ? cardNumber.substring(0, cardNumber.indexOf('X')) : '';
  const part2 = cardNumber ? cardNumber.substring(cardNumber.indexOf('X') + 1, cardNumber.lastIndexOf('X')) : '';
  const part3 = cardNumber ? cardNumber.substring(cardNumber.lastIndexOf('X') + 1) : '';

  const onSubmit = async (data) => {
    const number = part1 + data.FirstX + part2 + data.SecondX + part3;
    setLoader(true);
    acceptFnc(number);
  }

  return (
      <>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 max-h-80vh overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 lg:w-1/2 p-6 md:py-8 bg-white text-lg md:text-xl terms">
          <p className="font-nouvelrbold">{t('termsPopUpTextBold')}</p>
          <p>{t(`termsPopUpCardText`)}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5 font-semibold">
              {part1}
              <input type="text" {...register("FirstX", { required: true, maxLength: 1 })} className="inline-block w-4 outline-none text-right bg-white border-b border-solid border-formBorder" />
              {part2}
              <input type="text" {...register("SecondX", { required: true, maxLength: 1 })} className="inline-block w-4 outline-none text-right bg-white border-b border-solid border-formBorder" />
              {part3}
            </div>
            {(errors.FirstX || errors.SecondX) && <span className="text-error text-base">{t('errFieldRequired')}</span>}
            {popUpErrorText && <span className="text-error text-base">{popUpErrorText}</span>}
            <div className="md:flex mt-6 text-center">
              <button type="submit" className={`relative block w-full md:w-fit max-h-46px leading-3 p-4 border font-semibold text-base transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand hover:text-white ${loader ? 'pr-12' : ''}`}>
                {t('confirm')}
                {loader && <Loader position="top-3 right-5" />}
              </button>
              <Button anchor={t('close')} styles="mt-6 md:mt-0 md:ml-6 text-base leading-4" onClickFnc={rejectFnc} />
            </div>
          </form>
        </div>
      </>
  )
}

const WorkshopCard = () => {
  const { t } = useTranslation();
  const [data, setData] = useContext(Context);
  const [cardActive, setCardActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [popupCard, setPopupCard] = useState(false);
  const [popUpErrorText, setPopUpErrorText] = useState('');
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : null;

  useEffect(() => {
    if (data.user.cardNumber && !data.user.cardNumberConfirmedOn) {
      setPopupCard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.user.cardNumber && data.user.cardNumberConfirmedOn) {
      setCardActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.user.cardNumberConfirmedOn]);

  const popUpAcceptHandler = (number) => {
    JwtInterceptor
        .post(`${API_URL}Users/confirmCardNumber`, {cardNumber: number})
        .then(() => {
          setData({
            type: 'SET_USER',
            payload: {
              ...data.user,
              cardNumberConfirmedOn: true,
            },
          });
          setPopupCard(false);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpErrorText(errorTxt);
          } else {
            setPopUpErrorText(t('errDefault'));
          }
        })
        .finally(() => {
          setLoader(false);
        });
  }

  const popUpRejectHandler = () => {
    setPopupCard(false);
  }

  const activeCardHandler = () => {
    setPopupCard(true);
  }

  return (
      <>
        <Menu />
        {role === 'ServiceChief' &&
          <WorkshopMenu />
        }
        {popupCard && <CardPopUp cardNumber={data.user.cardNumber} acceptFnc={popUpAcceptHandler} rejectFnc={popUpRejectHandler} loader={loader} setLoader={setLoader} popUpErrorText={popUpErrorText} />}
        <div className="mdd:flex mt-10">
          <img src={cardActive ? '/images/card.png' : '/images/card_inactive.png'} alt="workshop card" className="h-fit md:max-w-400px mb-5 mdd:mb-0" />
          <div className="ml-5 text-base">
            <div className={`text-xl ${cardActive ? '' : 'flex flex-col lg:flex-row lg:items-center justify-between'}`}>
              <p className="mb-5 lg:mb-0">{cardActive ? t('youCardNumber') : t('cardNotActive')}</p>
              {cardActive ?
                <span className="font-bold"> {data.user.cardNumber}</span>
              :
                // <Button anchor={t('cardConfirm')} styles="text-base w-fit" onClickFnc={activeCardHandler} />
                <div className="relative max-h-46px px-4 py-3 font-semibold border border-black border-solid transition ease-in-out duration-300 stroke-black hover:cursor-pointer hover:bg-brand hover:border-brand hover:text-white hover:stroke-white text-base w-fit" onClick={activeCardHandler} >
                  {t('cardConfirm')}
                </div>
              }
            </div>
            <p className="mt-5">Zgodnie z zasadami Programu, każdy pełnoprawny uczestnik otrzyma Kartę przedpłaconą, która zasilana będzie punktami zdobytymi w Konkursach. Zasilenie karty następuje automatycznie, po zakończeniu i finalnym rozliczeniu danego Konkursu. Nie możesz samodzielnie przenieść zdobytych punktów na kartę prepaid. </p>
            <p className="my-5">Sprawdź, czy numer otrzymanej karty zgadza się z 16-cyfrowym numerem wskazanym powyżej i potwierdź kartę. Dopiero po Twoim potwierdzeniu, karta będzie aktywna. Karta będzie zasilana kwotą odpowiadającą liczbie zgromadzonych punktów po zakończeniu Konkursu, w którym Twój serwis zrealizował Cel Sprzedażowy. Uwaga! Jeśli zgubiłeś kartę przedpłaconą lub kod PIN, zgłoś się do nas niezwłocznie na adres kontakt@motrioserviceclub.com. Nie ma możliwości nadania nowego kodu PIN, dlatego musimy wysłać do Ciebie nową kartę. Koszt wydania nowej karty to 60 zł odejmowane z salda karty.</p>
            <DownloadLink text={t('cardRulesDownloadAnchor')}  link="/pdf/eprl_regulamin_uzytkowania_przedplaconej_karty_edenred_11_03_2024_PL.pdf" />
            <DownloadLink text={t('howCartActivate')} link="/pdf/card_neut_std_2_druk.pdf" />
            <p className="mt-5">
              <a href="https://nowy.myedenred.pl/" target="_blank" rel="noreferrer" className="flex items-center font-semibold transition ease-in-out duration-300 stroke-black hover:text-brand">
                <Icon id="card" viewBox="0 0 20 16" width="20" height="16" clsWrap="mr-2.5" />
                {t('checkCardStatus')}
              </a>
            </p>
          </div>
        </div>
      </>
  );
}

export default WorkshopCard;