import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const PopUp = ({ open, text, type, hideFnc }) => {
  return (
      <div className={`${open ? 'block' : 'hidden'}`}>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 p-6 bg-white">
          <Icon id="x" viewBox="0 0 32 32" width="32" height="32" clsWrap="absolute top-1 right-1 hover:cursor-pointer" onClickFnc={hideFnc} />
          <p className={`text-base ${type === 'error' ? 'text-error' : 'text-green-700'}`}>{text}</p>
        </div>
      </div>
  )
}

PopUp.defaultProps = {
  hideFnc: '',
  open: false,
  text: '',
  type: 'error'
}

PopUp.propTypes = {
  hideFnc: PropTypes.func,
  open: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
}

export default PopUp;