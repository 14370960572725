import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import ButtonLink from "../_shared/ButtonLink";
import Button from '../_shared/Button';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const ParticipantsFilters = ({ radioVal, setRadioVal, results }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';

  const radioHandler = (e) => {
    setRadioVal(e.currentTarget.value);
  }

  const getRaportHandler = () => {
    setLoading(true);

    JwtInterceptor
        .get(`${API_URL}Competitions/usersReport`, {responseType: 'blob'})
        .then((result) => {
          const href = URL.createObjectURL(result.data);
          const link = document.createElement('a');

          link.href = href;
          link.setAttribute('download', 'Report-export.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }

  return (
    <>
      <div className="flex items-baseline justify-between">
        <p className="mt-9 font-semibold text-base">{t('numberOfResults')} {results}</p>
      </div>
      <div className="lg:flex items-center justify-between">
        <div className="flex mt-10 mb-5 text-base font-semibold">
          <fieldset className="flex">
            <input id="workshops" type="radio" name="switch" value="1" className="w-6 h-6 hover:cursor-pointer" checked={radioVal === '1'} onChange={(e) => radioHandler(e)} />
            <label htmlFor="workshops" className="ml-1 hover:cursor-pointer">{t('workshops')}</label>
          </fieldset>
          <fieldset className="flex ml-10">
            <input id="participants" type="radio" name="switch" value="2" className="w-6 h-6 hover:cursor-pointer"  checked={radioVal === '2'} onChange={(e) => radioHandler(e)} />
            <label htmlFor="participants" className="ml-1 hover:cursor-pointer">{t('participants')}</label>
          </fieldset>
        </div>
        <div className="flex flex-col md:flex-row mb-5 lg:mb-0">
          {role === 'Admin' && radioVal === '2' &&
            <ButtonLink anchor={t('addNewUser')} href="/add-user" iconLeft="plusThin" styles="w-fit whitespace-nowrap" />
          }
          {role === 'Admin' &&
            <Button anchor={t('downloadUserBase')} iconLeft="download" loading={loading} styles="w-fit mt-2 whitespace-nowrap md:mt-0 md:ml-2" onClickFnc={() => getRaportHandler()} />
          }
        </div>
      </div>
    </>
  );
}

export default ParticipantsFilters;