import React from 'react';
import Icon from './Icon';
import Loader from '../_shared/Loader';
import PropTypes from "prop-types";

const EditTitle = ({ editFnc, editLinkText, isEdit, isLoading, sendFnc, text}) => {
  return (
      <h3 className="flex items-center justify-between w-full mb-6 text-3xl md:text-40px font-semibold">
        {text}
        {editLinkText && !isEdit &&
          <a href="/#" className="flex items-center font-semibold text-base" onClick={editFnc}>
            {editLinkText}
            <Icon id="edit" viewBox="0 0 32 32" width="32" height="32" clsWrap="ml-2" />
          </a>
        }
        {isEdit &&
          <div className="flex">
            <div className="relative w-8 h-8 border border-solid transition ease-in-out duration-300 hover:bg-brand hover:border-brand">
              <Icon id="x" viewBox="0 0 32 32" width="32" hieght="32" clsWrap="hover:cursor-pointer hover:fill-white" onClickFnc={editFnc} />
            </div>
            {isLoading ?
              <div className="relative w-8 h-8 ml-4 border border-solid transition ease-in-out duration-300 hover:bg-brand hover:border-brand">
                <Loader position="top-1 right-1" />
              </div>
            :
              <div className="relative w-8 h-8 ml-4 border border-solid transition ease-in-out duration-300 hover:bg-brand hover:border-brand">
                <Icon id="check" viewBox="0 0 32 32" width="32" hieght="32" clsWrap="hover:cursor-pointer hover:fill-white" onClickFnc={sendFnc} />
              </div>
            }
          </div>
        }
      </h3>
  )
}

EditTitle.defaultProps = {
  editLinkText: '',
  isEdit: false,
  isLoading: false,
  text: ''
};

EditTitle.propTypes = {
  editFnc: PropTypes.func,
  editLinkText: PropTypes.string,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  sendFnc: PropTypes.func,
  text: PropTypes.string.isRequired
};

export default EditTitle;