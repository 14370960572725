import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import LoaderBig from '../_shared/LoaderBig';
import Menu from '../Menu';
// import Pagination from "../_shared/Pagination";
import ParticipantsFilters from './ParticipantsFilters';
import ParticipantsTable from "./ParticipantsTable";
import PopUp from '../_shared/PopUp';
import JwtInterceptor from "../_shared/JwtInterceptors";
import WorkshopsTable from "./WorkshopsTable";
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const Participants = ({ blurePage }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  // const [page, setPage] = useState(1);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [users, setUsers] = useState([]);
  const [radioVal, setRadioVal] = useState('1'); //1-workshops 2-participants;
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const getWorkshops = () => {
      JwtInterceptor
          .get(`${API_URL}Services`)
          .then((response) => {
            setUsers(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              logout();
            }
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUp(true);
          }).finally(() => {
        setLoader(false);
      });
    }

    const getParticipants = () => {
      JwtInterceptor
          .get(`${API_URL}Users/all`)
          .then((response) => {
            setUsers(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              logout();
            }
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUp(true);
          }).finally(() => {
            setLoader(false);
          });
      }

      if (!blurePage) {
        setLoader(true);

        if (radioVal === '2') {
          getParticipants();
        } else {
          getWorkshops();
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioVal, reload, blurePage]);

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <>
        <Menu />
        <ParticipantsFilters radioVal={radioVal} setRadioVal={setRadioVal} results={users.length} />
        {loader ?
          <LoaderBig />
        :
          <>
            {radioVal === '1' ?
              <WorkshopsTable users={users} />
            :
              <ParticipantsTable users={users} setReload={setReload} />
            }
            {/*<Pagination allPage="3" actualPage={page} setPage={setPage} />*/}
          </>
        }
        <PopUp open={popUp} text={popUpText} type='error' hideFnc={popUpCloseHandler} />
      </>
  )
}

export default Participants;