import React, { useState } from 'react';
import AddUserForm from "./AddUserForm";
import Menu from "../Menu";

const AddUser = () => {
  const [profileImg, setProfileImg] = useState('');

  return (
      <>
        <Menu />
        <div className="md:flex mt-8">
          <div className="md:w-full">
            <img src={profileImg ? profileImg : '/images/contact.png'} alt="profile" className="h-fit w-full md:max-w-650px" />
          </div>
          <div className="mt-10 md:mt-0 md:ml-5 w-full">
            <AddUserForm setProfileImg={setProfileImg} />
          </div>
        </div>
      </>
  );
}

export default AddUser;