 import React, { useContext } from 'react';
 import AuthContext from './_shared/AuthContext';

const Footer = () => {
  const { setAuthToken, setUser } = useContext(AuthContext);
  const tempTokens = localStorage.getItem('tempTokens');

  const adminReloadHandler = (e, user) => {
    e.preventDefault();

    if (tempTokens) {
      localStorage.setItem('tokens', tempTokens);
      setUser();
      setAuthToken();
      localStorage.removeItem('tempTokens');
      window.location = "/";
    }
  }

  return (
      <footer className="font-poppins bg-motrioBg py-10 md:py-20  text-center">
        <p className="uppercase text-white text-base md:text-xl text-center">© 2024 Motrio Service Club</p>
        <a href="/pdf/polityka_prywatnosci.pdf" target="_blank" className="inline-block mt-10 font-nouvelrbold border-b border-solid text-white">Polityka prywatności</a>
        {tempTokens &&
            <>
              <br />
              <a href="/#" className="inline-block mt-10 font-nouvelrbold border-b border-solid text-white" onClick={(e) => adminReloadHandler(e)}>Powrót do konta</a>
            </>
        }
      </footer>
  )
}

export default Footer;