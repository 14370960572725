import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../_shared/Icon';
import LoaderBig from '../_shared/LoaderBig';
import Menu from '../Menu';
import WorkshopMenu from "./WorkshopMenu";
import PopUp from '../_shared/PopUp';
import AuthContext from '../_shared/AuthContext';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const TableRow = ({ data }) => {
  return (
      <tr>
        <td className="px-2 py-4 whitespace-nowrap">{data.competitionName}</td>
        <td className="px-2 py-4 whitespace-nowrap">{data.turnoverFormatted}</td>
        <td className="px-2 py-4">{data.score}</td>
      </tr>
  );
}

const WorkshopPoints = () => {
  const {t} = useTranslation();
  const { logout } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [points, setPoints] = useState([]);
  let rows = [];

  useEffect(() => {
    setLoader(true);

    JwtInterceptor
        .get(`${API_URL}Users/points`)
        .then((response) => {
          setPoints(response.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
        }).finally(() => {
          setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  rows = points.map((row, i) => <TableRow data={row} key={i} />);

  return (
      <>
        <Menu />
        <WorkshopMenu />
        <div className="mt-10">
          <div>
            <span className="font-semibold">{t('workshopPointsHistory')}</span>
          </div>
          {loader && <LoaderBig />}
          <PopUp open={popUp} text={popUpText} type='error' hideFnc={popUpCloseHandler} />
          <div className="overflow-y-auto md:overflow-y-hidden">
            <table className="w-full mt-4 text-base">
              <thead>
              <tr className="font-semibold text-left bg-black text-white">
                <th className="px-2 py-5 align-middle">{t('specialOffer')}</th>
                <th className="px-2 py-5 align-middle">{t('turnover')}</th>
                <th className="px-2 py-5 align-middle">{t('workshopPointsEarned')}</th>
              </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
          <p className="mt-10">
            <a href="https://nowy.myedenred.pl/" target="_blank" rel="noreferrer" className="flex items-center justify-end font-semibold transition ease-in-out duration-300 stroke-black hover:text-brand">
              <Icon id="card" viewBox="0 0 20 16" width="20" height="16" clsWrap="mr-2.5" />
              {t('checkCardStatus')}
            </a>
          </p>
        </div>
      </>
  );
}

export default WorkshopPoints;