import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { NavLink } from 'react-router-dom';
import Icon from '../_shared/Icon';
import PopUp from '../_shared/PopUp';
import AuthContext from '../_shared/AuthContext';

import JwtInterceptor from '../_shared/JwtInterceptors';
import Button from "../_shared/Button";

const API_URL = process.env.REACT_APP_API_URL;

const position = {
  admin: "Administrator",
  mop: "CCPR",
  serviceAdvisor: "Pracownik",
  serviceChief: "Właściciel"
};

const DeletePopUp = ({ deleteUser, setDeletePopUp, setPopUp, setPopUpText, setReload }) => {
  const { t } = useTranslation();

  const deleteUserHandler = () => {
    JwtInterceptor
        .delete(`${API_URL}Users/delete/${deleteUser.id}`)
        .then((response) => {
          setDeletePopUp(false);
          setReload(true);
        })
        .catch(function (error) {
          setDeletePopUp(false);
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUp('true');
        });
  }

  const closePopUpHandler = () => {
    setDeletePopUp(false);
  }

  return (
      <div>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-fit p-6 bg-white">
          <p className="text-base">{t('deleteUserQuestion')} <span className="font-nouvelrbold">{deleteUser.name}?</span></p>
          <div className="flex justify-center mt-5">
            <Button anchor={t('yes')} icon="check" onClickFnc={deleteUserHandler} />
            <Button anchor={t('no')} icon="x" onClickFnc={closePopUpHandler} styles="ml-5" />
          </div>
        </div>
      </div>
  );
}

const UserRow = ({ user, setDeleteUser, setDeletePopUp, setUsername }) => {
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : null;

  const userHandler = (e, username) => {
    e.preventDefault();
    setUsername(username);
  }

  const deleteHandler = () => {
    setDeleteUser({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`
    });

    setDeletePopUp(true);
  };

  return (
      <tr className="even:bg-tableEven">
        <td className="px-2 py-4">{user.service ? user.service.name : ''}</td>
        {role === 'Admin' ?
          <td className="px-2 py-4"><a href="/#" className="underline" onClick={(e) => userHandler(e, user.userName)}>{user.firstName} {user.lastName}</a></td>
        :
          <td className="px-2 py-4">{user.firstName} {user.lastName}</td>
        }
        <td className="px-2 py-4">{user.roles ? position[user.roles[0]] : ''}</td>
        <td className="px-2 py-4">{user.phoneNumber}</td>
        <td className="px-2 py-4">{user.email}</td>
        <td className="px-2 py-4">{user.createdOn}</td>
        <td className="px-2 py-4">{user.cardNumberConfirmedOn}</td>
        {role === 'Admin' &&
          <>
            <td className="relative py-4 px-2">
              <NavLink to={`/add-user/${user.id}`}>
                <Icon id="edit" viewBox="0 0 24 24" width="24" height="24" clsWrap="relative top-1 hover:cursor-pointer hover:cursor-pointer hover:scale-125 hover:cursor-pointer" />
              </NavLink>            </td>
            <td className="relative py-4 px-2">
              <Icon id="delete" viewBox="0 0 24 24" width="30" height="30" clsWrap="absolute top-4 transform transition duration-y hover:cursor-pointer hover:scale-125 hover:cursor-pointer" onClickFnc={deleteHandler} />
            </td>
          </>
        }
      </tr>
  );
}

const ParticipantsTable = ({ users, setReload }) => {
  const { t } = useTranslation();
  const { setAuthToken, setUser } = useContext(AuthContext);
  const [deleteUser, setDeleteUser] = useState({});
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [username, setUsername] = useState('');
  const usersRow = users.map(user => <UserRow user={user} key={user.id} setDeleteUser={setDeleteUser} setDeletePopUp={setDeletePopUp} setUsername={setUsername} />);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : null;

  useEffect(() => {
    if (username) {
      JwtInterceptor
          .post(`${API_URL}Users/token/${username}`)
          .then((response) => {
            const tempTokens = localStorage.getItem('tokens');
            localStorage.setItem('tempTokens', tempTokens);

            localStorage.setItem('tokens', JSON.stringify(response.data));
            setUser(jwtDecode(response.data.token));
            setAuthToken(JSON.parse(JSON.stringify(response.data)));
            window.location = "/";
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <div className="overflow-y-auto md:overflow-y-hidden">
        <table>
          <thead className="w-full mt-4 text-base">
            <tr className="font-semibold text-left bg-black text-white">
              <th className="px-2 py-5 align-middle">{t('workshop')}</th>
              <th className="px-2 py-5 align-middle">{t('participant')}</th>
              <th className="px-2 py-5 align-middle">{t('position')}</th>
              <th className="px-2 py-5 align-middle">{t('phone')}</th>
              <th className="px-2 py-5 align-middle">{t('emailAdress')}</th>
              <th className="px-2 py-5 align-middle">{t('registration')}</th>
              <th className="px-2 py-5 align-middle">{t('cardConfirmS')}</th>
              {role === 'Admin' &&
                <>
                  <th className="px-5 py-5 align-middle"></th>
                  <th className="px-5 py-5 align-middle"></th>
                </>
              }
            </tr>
          </thead>
          <tbody className="text-sm text-tableText">
            {usersRow}
          </tbody>
        </table>
        <PopUp open={popUp} text={popUpText} type='error' hideFnc={popUpCloseHandler} />
        {deletePopUp && <DeletePopUp deleteUser={deleteUser} setDeletePopUp={setDeletePopUp} setPopUp={setPopUp} setPopUpText={setPopUpText} setReload={setReload} />}
      </div>
  );
}

export default ParticipantsTable;